<template>
  <v-container>
    <v-row v-for="(mission, missionIndex) in missions" :key="missionIndex">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <v-card
          :id="'mission-' + mission.id"
          flat
          :dark="mission.id === currentMissionId"
          @click="$emit('select', mission.id)"
        >
          <v-card-text>
            <h4 class="title">
              {{ mission.title }}
            </h4>
            <div class="d-flex align-center">
              <div class="flex-shrink">
                <ProfilePhoto
                  :user-id="mission.userId"
                  :user-name="mission.userName"
                  :url="mission.profilePhoto"
                />
              </div>
              <div class="subheading">
                {{ mission.userName }}
              </div>
            </div>
            <div class="body-1">
              {{ mission.description }}
            </div>
            <v-row>
              <v-col cols="5">
                <div>{{ $t('started') }}</div>
                <div>{{ $t('expiryDate') }}</div>
                <div :class="mission.alarmFired ? 'alarm-sent' : 'finished'">
                  {{ mission.alarmFired ? $t('alarmSent') : $t('finished') }}
                </div>
              </v-col>
              <v-col cols="7">
                <div>{{ mission.startedStr }}</div>
                <div>{{ mission.expiresStr }}</div>
                <div :class="mission.alarmFired ? 'alarm-sent' : 'finished'">
                  {{ mission.alarmFired ? mission.alarmStartedStr : mission.finishedStr }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="mission.alarmFired">
            <v-spacer />
            <v-btn color="#cc2e38" class="white--text" @click.stop="viewAlert(mission.alarmId)">
              {{ $t('viewAlarm') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '@/router';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { easeInOutCubic } from 'vuetify/es5/services/goto/easing-patterns';

export default {
  name: 'FinishedMissions',

  components: {
    ProfilePhoto,
  },

  props: {
    missions: {
      required: true,
      type: Array,
    },

    currentMissionId: {
      type: Number,
      required: true,
    },
  },

  watch: {
    missions() {
      this.scrollToCurrentMission();
    },
  },

  mounted() {
    this.scrollToCurrentMission();
  },

  methods: {
    viewAlert: (alertId) => {
      router.push(`/alerts/${alertId}`);
    },

    scrollToCurrentMission() {
      if (this.missions.length > 0) {
        const mission = this.missions.find((mission) => mission.id === this.currentMissionId);
        if (mission) {
          setTimeout(() => {
            this.$vuetify.goTo('#mission-' + mission.id, {
              duration: 300,
              easing: easeInOutCubic,
            });
          }, 500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.finished {
  color: #63898a;
}
.alarm-sent {
  color: #940012;
}
</style>
