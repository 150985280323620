<template>
  <v-container>
    <v-row>
      <h3 class="title text-uppercase px-3 pt-4">
        {{ $t('ongoingMissions') }}
      </h3>
    </v-row>
    <v-row>
      <v-col
        v-for="(mission, missionIndex) in missions"
        :key="missionIndex"
        cols="6"
        lg="3"
        class="pa-2"
      >
        <v-card
          :id="'mission-' + mission.id"
          height="100%"
          :dark="mission.id === currentMissionId"
          @click="$emit('select', mission.id)"
        >
          <v-card-title>
            <v-row>
              <v-col cols="10" class="py-0">
                <h4 class="body-1">
                  {{ mission.title }}
                </h4>
              </v-col>
              <v-col cols="2" class="py-0 text-right">
                <v-btn
                  icon
                  class="ma-0 pr-4"
                  :title="$t('showLastKnownLocation')"
                  @click.stop="showLastKnownLocation(mission)"
                >
                  <v-icon>fa-map-marked</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <div class="d-flex align-center">
              <div class="flex-shrink">
                <ProfilePhoto
                  class="light-border mr-4"
                  :user-id="mission.userId"
                  :user-name="mission.userName"
                  :url="mission.profilePhoto"
                />
              </div>
              <div class="title">
                {{ mission.userName }}
              </div>
            </div>

            <div v-if="mission.description.length > 0" class="body-1">
              {{ $t('comment') }}: {{ mission.description }}
            </div>
            <div
              class="body-1"
              :class="mission.expiresSoon ? 'yellowDark--text font-weight-bold' : ''"
            >
              {{ mission.relativeTime }} ({{ mission.expiresStr }})
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { easeInOutCubic } from 'vuetify/es5/services/goto/easing-patterns';

export default {
  name: 'ActiveMissions',

  components: {
    ProfilePhoto,
  },

  props: {
    missions: {
      required: true,
      type: Array,
    },

    showLastKnownLocation: {
      required: true,
      type: Function,
    },

    currentMissionId: {
      type: Number,
      required: true,
    },
  },

  watch: {
    missions() {
      this.scrollToCurrentMission();
    },
  },

  mounted() {
    this.scrollToCurrentMission();
  },

  methods: {
    scrollToCurrentMission() {
      if (this.missions.length > 0) {
        const mission = this.missions.find((mission) => mission.id === this.currentMissionId);
        if (mission) {
          setTimeout(() => {
            this.$vuetify.goTo('#mission-' + mission.id, {
              duration: 300,
              easing: easeInOutCubic,
            });
          }, 500);
        }
      }
    },
  },
};
</script>
