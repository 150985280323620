<template>
  <v-container v-if="missions.length > 0" pa-0>
    <h3 class="subheading text-uppercase px-3 pt-4">
      {{ $t('expiredMissions') }}
    </h3>
    <v-container>
      <v-row>
        <v-col
          v-for="(mission, missionIndex) in missions"
          :key="missionIndex"
          cols="12"
          md="6"
          class="mission pa-0"
        >
          <v-card
            :color="mission.id === currentMissionId ? 'black' : 'redPrimary'"
            dark
            class="mission-card"
            @click="$emit('select', mission.id)"
          >
            <v-card-text class="white--text">
              <h4 class="body-1">
                {{ mission.title }}
              </h4>
              <v-row>
                <v-col>
                  <ProfilePhoto
                    :user-id="mission.userId"
                    :user-name="mission.userName"
                    :url="mission.profilePhoto"
                  />
                  <div class="headline">
                    {{ mission.userName }}
                  </div>
                  <div v-if="mission.description.length > 0" class="body-1">
                    {{ $t('comment') }}: {{ mission.description }}
                  </div>
                  <div class="body-1">{{ mission.relativeTime }} ({{ mission.expiresStr }})</div>
                </v-col>

                <v-col class="text-right">
                  <v-btn
                    icon
                    large
                    class="ma-0"
                    :title="$t('showLastKnownLocation')"
                    @click.stop="showLastKnownLocation(mission)"
                  >
                    <v-icon>fa-map-marked</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="white"
                :class="mission.id === currentMissionId ? 'black--text' : 'redPrimary--text'"
                @click.stop="finishMission(mission.id)"
              >
                {{ $t('finish') }}
              </v-btn>
              <v-btn
                color="white"
                :class="mission.id === currentMissionId ? 'black--text' : 'redPrimary--text'"
                :disabled="!mission.alarmFired"
                @click.stop="viewAlert(mission.alarmId)"
              >
                {{ $t('viewAlarm') }}
              </v-btn>
              <v-btn
                color="white"
                :class="mission.id === currentMissionId ? 'black--text' : 'redPrimary--text'"
                tag="a"
                :href="`tel:${mission.userPhone}`"
              >
                {{ mission.userPhone }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import router from '@/router';
import ProfilePhoto from '@/components/ProfilePhoto.vue';

export default {
  name: 'ExpiredMissions',

  components: {
    ProfilePhoto,
  },

  props: {
    missions: {
      required: true,
      type: Array,
    },

    finishMission: {
      required: true,
      type: Function,
    },

    showLastKnownLocation: {
      required: true,
      type: Function,
    },

    currentMissionId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    viewAlert: (alertId) => {
      router.push(`/alerts/${alertId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.red-text {
  color: #cc2e38;
}

.mission {
  margin-bottom: 16px;

  @media all and (min-width: 700px) {
    .mission-card {
      margin: 0 7px;
    }
  }

  @media all and (min-width: 960px) {
    .mission-card {
      height: 100%;
    }
  }
}
</style>
