<template>
  <v-container pt-4>
    <v-row>
      <div class="d-flex align-center">
        <h3 class="title text-uppercase px-3 ma-0">
          {{ $t('missionAlarmSubscribers') }}
        </h3>
        <template v-if="!editMode">
          <v-btn @click="editMode = true">
            {{ $t('edit') }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn class="mr-3" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="#63898a" dark @click="saveMissionAlarmSubscribers">
            {{ $t('save') }}
          </v-btn>
        </template>
      </div>
    </v-row>

    <v-row>
      <p class="px-3">
        {{ $t('missionAlarmSubscribersInfo') }} {{ isAdmin ? '' : $t('canBeSetByAdmins') }}
      </p>

      <p v-if="!subscribersSet" class="px-3">
        {{ $t('missionAlarmSubscribersEmpty') }}
      </p>
    </v-row>

    <v-row>
      <v-col v-for="(user, index) in missionAlarmSubscribers" :key="index" cols="6" lg="3">
        <v-card height="100%" elevation="0" color="#333" dark>
          <v-card-text>
            <div class="d-flex align-center">
              <div class="flex-shrink">
                <ProfilePhoto
                  class="light-border mr-4"
                  :user-id="user.id"
                  :user-name="user.name"
                  :url="user.profilePhoto"
                />
              </div>
              <div>
                {{ user.name }}
              </div>
              <v-spacer />
              <v-btn v-if="editMode" icon small @click="removeSubscriber(user.id)">
                <v-icon>fal fa-times</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="editMode" cols="6" lg="3">
        <v-card height="100%" elevation="0" color="#333" dark>
          <v-card-text>
            <v-autocomplete
              v-model="userSearch"
              :items="selectableUsers"
              :label="$t('addUser')"
              item-text="name"
              return-object
              hide-details
              class="pt-0"
              prepend-icon="fas fa-plus"
              @input="addSubscriber"
            >
              <template #selection>
                <div />
              </template>
              <template #item="data">
                <ProfilePhoto
                  class="mr-4"
                  :user-id="data.item.id"
                  :user-name="data.item.name"
                  :url="data.item.profilePhoto"
                />
                <v-list-item-content>
                  {{ data.item.name }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/util/api';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import auth from '@/util/auth';

export default {
  name: 'MissionAlarmSubscribers',

  components: {
    ProfilePhoto,
  },

  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },

    users: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    missionAlarmSubscribers: [],
    editMode: false,
    userSearch: '',
    subscribersSet: false,
    events: [],
  }),

  computed: {
    selectableUsers() {
      return this.users.filter(
        (user) => !this.missionAlarmSubscribers.find((subscriber) => user.id === subscriber.id),
      );
    },
  },

  created() {
    this.loadMissionAlarmSubscribers();
  },

  mounted() {
    this.subscribeToSignalREvents(auth.getCustomerId());
  },
  beforeDestroy() {
    this.unregisterSignalREvents();
  },
  methods: {
    loadMissionAlarmSubscribers() {
      api.getMissionAlarmSubscribers().then((res) => {
        this.missionAlarmSubscribers = res.subscribers.map((user) => ({
          id: user.userId,
          name: user.name,
          profilePhoto: user.profilePhoto,
        }));
        this.subscribersSet = res.subscribersSet;
      });
    },

    removeSubscriber(id) {
      this.missionAlarmSubscribers = this.missionAlarmSubscribers.filter((user) => user.id !== id);
    },

    addSubscriber(user) {
      this.userSearch = '';

      this.missionAlarmSubscribers.push({
        id: user.id,
        name: user.name,
        profilePhoto: user.profilePhoto,
      });
    },

    subscribeToSignalREvents(customerId) {
      let self = this;
      // Unregister all events
      this.events.forEach((event) => this.$signalR.off(event));

      this.events = [`updatedMissionSubscribersCustomerId:${customerId}`];

      this.events.forEach((event) =>
        this.$signalR.on(event, () => {
          if (event === `updatedMissionSubscribersCustomerId:${customerId}`) {
            self.loadMissionAlarmSubscribers();
          }
        }),
      );
    },
    unregisterSignalREvents() {
      this.events.forEach((event) => this.$signalR.off(event));
    },
    cancel() {
      this.editMode = false;
      this.loadMissionAlarmSubscribers();
    },

    saveMissionAlarmSubscribers() {
      this.editMode = false;
      const ids = this.missionAlarmSubscribers.map((user) => user.id);
      api.setMissionAlarmSubscribers(ids).then(() => {
        this.loadMissionAlarmSubscribers();
      });
    },
  },
};
</script>
