<template>
  <div>
    <v-dialog v-model="mapDialog" max-width="500px" eager>
      <div ref="mapContainer" />
    </v-dialog>
    <v-tabs v-model="tab" slider-color="#63898a">
      <v-tab
        v-for="(tab, index) in ['activeMissions', 'closedMissions']"
        :key="index"
        ripple
        class="u-clickable"
        @click="onTabChangedByUserInteraction"
      >
        {{ $t(tab) }}
      </v-tab>

      <v-tab-item>
        <ExpiredMissions
          :show-last-known-location="showLastKnownLocation"
          :missions="expiredMissions"
          :current-mission-id="currentMissionId"
          :finish-mission="finishMission"
          @select="selectMission"
        />
        <MissionAlarmSubscribers :users="users" :is-admin="isAdmin" />
        <ActiveMissions
          :show-last-known-location="showLastKnownLocation"
          :missions="ongoingMissions"
          :current-mission-id="currentMissionId"
          @select="selectMission"
        />
      </v-tab-item>

      <v-tab-item>
        <FinishedMissions
          :missions="finishedMissions"
          :current-mission-id="currentMissionId"
          @select="selectMission"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import api from '@/util/api';
import moment from 'moment';
import ExpiredMissions from './ExpiredMissions.vue';
import MissionAlarmSubscribers from './MissionAlarmSubscribers.vue';
import ActiveMissions from './ActiveMissions.vue';
import FinishedMissions from './FinishedMissions.vue';
import confirmDialog from '@/components/dialog/confirmDialog';
import { decodeEncodedNonAsciiCharacters } from '@/util/encoding';
import getUserProfile from '@/util/getUserProfile';
import router from '@/router';
import htmlMessageDialog from '@/components/dialog/htmlMessageDialog';
import { isAdmin } from '@/consts/roles';
import map from '@/map/map';
import { ap, cr, st } from '@/lib/ui/dom';
import is from '@/lib/util/is';

export default {
  name: 'Missions',

  components: {
    ExpiredMissions,
    MissionAlarmSubscribers,
    ActiveMissions,
    FinishedMissions,
  },

  data: () => ({
    tab: null,
    mapDialog: null,
    locationMap: null,
    expiredMissions: [],
    ongoingMissions: [],
    finishedMissions: [],
    currentMissionId: -1,
    users: [],
    isAdmin: false,
    events: [],
  }),

  beforeDestroy() {
    this.events.forEach((event) => this.$signalR.off(event));
  },

  mounted() {
    if (this.$route.params.id) {
      const id = parseInt(this.$route.params.id, 10);
      if (is.number(id)) {
        this.currentMissionId = id;
      }
    }

    this.checkAccess();
    this.locationMap = map();

    st(this.locationMap.element, {
      height: '500px',
    });
  },

  methods: {
    checkAccess() {
      getUserProfile().then((profile) => {
        const role = profile.user.role;

        if (!profile.features.find((feature) => feature.featureName === 'mission')) {
          htmlMessageDialog(
            this.$t('featureUnavailable'),
            this.$t('featureUnavailableDescription', null, {
              mail: 'sales@teamalert.net',
              featureName: this.$t('mission'),
            }),
            this.$t('ok'),
          )
            .open()
            .then(() => {
              router.replace('/');
            });
        } else {
          this.loadUsers().then(() => {
            this.subscribeToNewMissions(profile.customer.id);
            this.loadActiveMissions();
            this.loadFinishedMissions();
          });
        }

        this.isAdmin = isAdmin(role);
      });
    },

    subscribeToNewMissions(customerId) {
      this.events = [
        `newMissionCustomerId:${customerId}`,
        `missionFinishedCustomerId:${customerId}`,
      ];

      this.events.forEach((event) =>
        this.$signalR.on(event, () => {
          this.loadActiveMissions();
          this.loadFinishedMissions();
        }),
      );
    },

    loadActiveMissions() {
      api.getActiveCustomerMissions().then((res) => {
        const missions = res.missions;
        const now = moment();

        missions.forEach((mission) => {
          mission.title = decodeEncodedNonAsciiCharacters(mission.title);
          mission.description = decodeEncodedNonAsciiCharacters(mission.description);

          const end = moment(mission.end);
          mission.end = end;
          let expiresOrExpired;
          if (end.isAfter(now)) {
            expiresOrExpired = this.$t('expires');
          } else {
            expiresOrExpired = this.$t('expired');
          }
          mission.relativeTime = expiresOrExpired + ' ' + end.fromNow();
          mission.expiresStr = end.format('DD.MM.YYYY HH:mm');

          const diff = end.diff(now, 'minutes');
          mission.expiresSoon = diff > 0 && diff < 15;

          this.setUserInfo(mission);

          if (this.currentMissionId === mission.id) {
            this.tab = 0;
          }
        });

        this.expiredMissions = missions
          .filter((mission) => mission.end.isSameOrBefore(now))
          .sort((a, b) => b.end.diff(a.end)); // Show newly expired missions first
        this.ongoingMissions = missions.filter((mission) => mission.end.isAfter(now));
      });
    },

    loadFinishedMissions() {
      const format = 'DD.MM.YYYY HH:mm';
      api.getFinishedCustomerMissions().then((res) => {
        const missions = res.missions;
        missions.forEach((mission) => {
          mission.title = decodeEncodedNonAsciiCharacters(mission.title);
          mission.description = decodeEncodedNonAsciiCharacters(mission.description);

          mission.start = moment(mission.start);
          mission.end = moment(mission.end);
          mission.startedStr = mission.start.format(format);
          mission.expiresStr = mission.end.format(format);
          if (mission.finished) {
            mission.finished = moment(mission.finished);
            mission.finishedStr = mission.finished.format(format);
          }
          if (mission.alarmStarted) {
            mission.alarmStarted = moment(mission.alarmStarted);
            mission.alarmStartedStr = mission.alarmStarted.format(format);
          }

          this.setUserInfo(mission);

          if (this.currentMissionId === mission.id) {
            this.tab = 1;
          }
        });
        this.finishedMissions = missions.sort((a, b) => b.end.diff(a.end));
      });
    },

    loadUsers() {
      return api.getUsers().then((res) => {
        this.users = res.users;
      });
    },

    setUserInfo(mission) {
      const user = this.users.find((user) => user.userId === mission.userId);
      if (user) {
        mission.userName = user.name;
        mission.userPhone = user.mobile;
        mission.profilePhoto = user.profilePhoto;
      }
    },

    finishMission(missionId) {
      confirmDialog(
        this.$t('finishMission'),
        this.$t('finishMissionMsg'),
        this.$t('cancel'),
        this.$t('finish'),
      )
        .open()
        .then((confirmed) => {
          if (confirmed) {
            api.finishMission(missionId).then(() => {
              this.loadActiveMissions();
            });
          }
        });
    },

    showLastKnownLocation(mission) {
      this.mapDialog = true;
      this.$refs.mapContainer.innerHTML = '';
      this.$refs.mapContainer.append(this.locationMap.element);

      api.getLastKnownPositions(mission.userId).then((res) => {
        this.locationMap.loaded.then(() => {
          this.locationMap.redrawTiles();
          this.locationMap.clearPositions();

          const devicePositionsMap = {};

          res.positions.forEach((position) => {
            if (!devicePositionsMap[position.deviceId]) {
              devicePositionsMap[position.deviceId] = [];
            }

            devicePositionsMap[position.deviceId].push(position);
          });

          const lastKnownPositions = [];

          Object.keys(devicePositionsMap).forEach((deviceId) => {
            const positionsForDevice = devicePositionsMap[deviceId];

            positionsForDevice.sort((a, b) => moment(b.positionTime) - moment(a.positionTime));

            const lastKnownPosition = positionsForDevice[0];
            if (lastKnownPosition) {
              lastKnownPositions.push(lastKnownPosition);
            }
          });

          lastKnownPositions.forEach((position) => {
            this.locationMap.addPosition({
              id: mission.userId,
              name: position.deviceName,
              latLng: {
                lat: position.lat,
                lng: position.lng,
                radius: position.radius,
              },
              popupContent: ap(
                cr('div'),
                cr('h3', 'u-typ-caption', position.userName),
                ap(cr('p'), this.$t('device'), ': ', position.deviceName),
              ),
            });
          });
          this.locationMap.fitPositions(12);
        });
      });
    },

    onTabChangedByUserInteraction() {
      window.history.replaceState({}, document.title, '/missions');
      this.currentMissionId = -1;
    },

    selectMission(missionId) {
      window.history.replaceState({}, document.title, '/missions/' + missionId);
      this.currentMissionId = missionId;
    },
  },
};
</script>
